/* eslint-disable react-hooks/exhaustive-deps */
import { ItemSumberDayaApi } from "api"
import Axios from "axios"
import {
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  InputSearch,
  Pagination,
  Switch,
  Td,
  Th,
  UpdateButton
} from "components"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { TableNumber } from "utilities"
import { ModalAction } from "./Comps"
import {
  formInitialValues,
  formSubmmitMapper,
  formValidationSchema,
} from "./Utils"
import { ButtonGroup } from "react-bootstrap"

export const ListItemSumberDaya = ({ setNavbarTitle }) => {
  // Title
  const title = "ITEM SUMBER DAYA"

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true)

  // STATE SEARCHING
  const [searchKey, setSearchKey] = useState("")

  // STATE DATA KELOMPOK
  const [data, setData] = useState([])
  const [dropdown, setDropdown] = useState({ kategori: {}, satuan: {} })
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })

  const [modalConfig, setModalConfig] = useState({
    type: "add",
    show: false,
    data: {},
  })

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  })

  const [searchTime, setSearchTime] = useState(null)

  const mappingKategori = (data) =>
    data.map((val) => ({
      label: val?.nama_kategori_anggaran ?? "",
      value: val?.id_kategori_anggaran ?? "",
    }))

  const mappingSatuan = (data) =>
    data.map((val) => ({
      label: val?.nama ?? "",
      value: val?.id ?? "",
    }))

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true)

    Axios.all([
      ItemSumberDayaApi.getPage({
        q: searchKey,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
      ItemSumberDayaApi.dropdownKategori(),
      ItemSumberDayaApi.dropdownSatuan(),
    ])
      .then(
        Axios.spread((res, kategori, satuan) => {
          setData(res?.data?.data?.data)
          setDropdown({
            kategori: mappingKategori(kategori?.data?.data),
            satuan: mappingSatuan(satuan?.data?.data),
          })
          setPaginationConfig({
            ...paginationConfig,
            dataCount: res?.data?.data?.data_count,
            totalPage: res?.data?.data?.total_page,
          })
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
            show: true,
          })
        }
        setIsLoading(false)
      })
  }

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true)
    setAlertConfig({ ...alertConfig, show: false })

    const value = {
      id_item_sumber_daya: id,
    }

    const onLoadedSuccess = () => {
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
        show: true,
      })
    }

    const onLoadedFailed = () => {
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
        show: true,
      })
    }

    status === true
      ? ItemSumberDayaApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : ItemSumberDayaApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
  }

  const onInputSearch = (text) => {
    const key = text.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
          show: key === "" ? false : true,
        })
      }, 750)
    )
  }

  const formSubmitHandler = (value, { setSubmitting, resetForm }) => {
    setIsLoading(true)
    if (modalConfig.type === "edit") {
      ItemSumberDayaApi.update(formSubmmitMapper(value))
        .then(() => {
          setAlertConfig({
            show: true,
            text: "Data berhasil diubah !!",
            variant: "primary",
          })
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            text: "Data gagal disimpan !!",
            variant: "danger",
          })
        })
        .finally(() => {
          setModalConfig({ data: {}, type: "add", show: false })
          resetForm({
            values: {
              keterangan: "",
              idKelompok: "",
              namaItem: "",
              idKategori: "",
              namaKategori: "",
            },
          })
          setIsLoading(false)
          setSubmitting(false)
          getData()
        })
    }

    if (modalConfig.type === "add") {
      ItemSumberDayaApi.create(formSubmmitMapper(value))
        .then(() => {
          setAlertConfig({
            show: true,
            text: "Data berhasil disimpan !!",
            variant: "primary",
          })
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            text: "Data gagal disimpan !!",
            variant: "danger",
          })
        })
        .finally(() => {
          setModalConfig({ data: {}, type: "add", show: false })
          resetForm({
            values: {
              keterangan: "",
              idKelompok: "",
              namaItem: "",
              idKategori: "",
              namaKategori: "",
            },
          })
          setIsLoading(false)
          setSubmitting(false)
          getData()
        })
    }
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR
    getData() // GET DATA DARI SERVER

    return () => {
      setIsLoading(false)
    }
  }, [
    setNavbarTitle,
    paginationConfig?.page,
    paginationConfig?.dataLength,
    searchKey,
  ])

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <thead>
            <tr className="text-center">
              <Th>No.</Th>
              <Th>Aksi</Th>
              <Th>Nama Item Sumber Daya</Th>
              <Th>Satuan</Th>
              <Th>Kategori Anggaran</Th>
              <Th>Keterangan</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => {
              return (
                <tr key={val.id_item_sumber_daya}>
                  <Td className="text-center" width="20px">
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </Td>
                  <Td width="100px">
                    <div className="d-flex justify-content-center">
                      <ButtonGroup>
                        <UpdateButton
                          size="sm"
                          onClick={() => {
                            setModalConfig({
                              show: true,
                              type: "edit",
                              data: val,
                            })
                          }}
                        />
                      </ButtonGroup>
                      <Switch
                        id={val.id_item_sumber_daya}
                        checked={val.is_hidden ? false : true}
                        wrapperClassName="mx-1"
                        onChange={() =>
                          changeDataStatus(
                            val.is_hidden,
                            val.id_item_sumber_daya
                          )
                        }
                      />
                    </div>
                  </Td>
                  <Td>{val.nama_item_sumber_daya ?? "-"}</Td>
                  <Td>{val.nama_satuan ?? "-"}</Td>
                  <Td>{val.nama_kategori_anggaran ?? "-"}</Td>
                  <Td>{val.keterangan ?? "-"}</Td>
                </tr>
              )
            })}
          </tbody>
        </CRUDLayout.Table>
        {data.length > 0 && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch
                onChange={(e) => {
                  onInputSearch(e)
                }}
              />
            </div>
          </CRUDLayout.HeadSearchSection>

          {/* Button Section */}
          <CRUDLayout.HeadButtonSection>
            <CreateButton
              onClick={() =>
                setModalConfig({
                  type: "add",
                  show: true,
                  data: {},
                })
              }
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        {/* Alert */}
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />

        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <>
            <b>List Data Item Sumber Daya</b>
            <Table />
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        <Formik
          enableReinitialize
          initialValues={formInitialValues(modalConfig?.data)}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {modalConfig?.show && (
            <ModalAction
              setModalConfig={setModalConfig}
              modalConfig={modalConfig}
              dropdown={dropdown}
            />
          )}
        </Formik>
      </CRUDLayout>
    </>
  )
}
