/* eslint-disable react-hooks/exhaustive-deps */
import { ProgramApi } from "api"
import Axios from "axios"
import {
  Alert,
  ApprovalStatusButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  Tr,
  UpdateButton,
} from "components"
import Thead from "components/Table/THead"
import { useEffect, useState } from "react"
import { DateConvert, TableNumber } from "utilities"
import { ModalAction, ModalFilter } from "./Comps"
import { formSubmmitMapper } from "./Utils"
import { ButtonGroup } from "react-bootstrap"

export const ListProgram = ({ setNavbarTitle }) => {
  // Title
  const title = "Program"

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true)

  // STATE SEARCHING
  const [searchKey, setSearchKey] = useState("")

  // STATE DATA KELOMPOK
  const [data, setData] = useState([])

  const [dropdown, setDropdown] = useState({
    unitOrganisasi: [],
    penanggungJawab: [],
    unitProduksi: [],
  })

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: false,
      tgl_program_start: undefined,
      tgl_program_end: undefined,
      nama_unit_organisasi: undefined,
      id_penanggung_jawab_program: undefined,
      id_unit_produksi: undefined,
      status_approval_program: undefined,
    },
    pagination: {
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    },
  })

  const [showModalFilter, setShowModalFilter] = useState(false)

  const [modalConfig, setModalConfig] = useState({
    type: "add",
    show: false,
    id: "",
  })

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  })

  const [searchTime, setSearchTime] = useState(null)

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true)

    Axios.all([
      ProgramApi.getPage({
        q: searchKey,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_program_start: dataFilter?.filter?.tgl_program_start,
        tgl_program_end: dataFilter?.filter?.tgl_program_end,
        nama_unit_organisasi: dataFilter?.filter?.nama_unit_organisasi,
        id_unit_produksi: dataFilter?.filter?.id_unit_produksi,
        id_penanggung_jawab_program:
          dataFilter?.filter?.id_penanggung_jawab_program,
        status_approval_program: dataFilter?.filter?.status_approval_program,
      }),
    ])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data?.data)

          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data?.data_count,
              totalPage: res?.data?.data?.total_page,
            },
          })
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false)
      })
  }

  // FUNCTION GET DATA DROPDOWN DARI SERVER
  const getDropdown = () => {
    Axios.all([
      ProgramApi.dropdownUnitOrganisasi(),
      ProgramApi.dropdownKaryawan(),
      ProgramApi.dropdownUnitProduksi(),
    ]).then(
      Axios.spread((unit, karyawan, produksi) => {
        const mappingDropdownUnitOrganisasi = (data) =>
          data.map((val) => ({
            label: val.nama_unit_organisasi,
            value: val.id_unit_organisasi,
          }))
        const mappingDropdownPenanggungJawab = (data) =>
          data.map((val) => ({
            label: val.nama_karyawan,
            value: val.id_karyawan,
          }))
        const mappingDropdownUnitProduksi = (data) =>
          data.map((val) => ({
            label: val.nama_unit_produksi,
            value: val.id_unit_produksi,
          }))

        setDropdown({
          unitOrganisasi: mappingDropdownUnitOrganisasi(unit?.data?.data ?? []),
          penanggungJawab: mappingDropdownPenanggungJawab(
            karyawan?.data?.data ?? []
          ),
          unitProduksi: mappingDropdownUnitProduksi(produksi?.data?.data ?? []),
        })
      })
    )
  }

  const onInputSearch = (text) => {
    const key = text.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
          show: key === "" ? false : true,
        })
      }, 750)
    )
  }

  const formSubmitHandler = (value, { setSubmitting }) => {
    setIsLoading(true)
    if (modalConfig.type === "edit") {
      ProgramApi.update(formSubmmitMapper(value))
        .then(() => {
          setAlertConfig({
            show: true,
            text: "Data berhasil diubah !!",
            variant: "primary",
          })
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            text: "Data gagal disimpan !!",
            variant: "danger",
          })
        })
        .finally(() => {
          setModalConfig({ id: "", type: "add", show: false })
          setIsLoading(false)
          setSubmitting(false)
          getData()
        })
    }

    if (modalConfig.type === "add") {
      ProgramApi.create(formSubmmitMapper(value))
        .then(() => {
          setAlertConfig({
            show: true,
            text: "Data berhasil disimpan !!",
            variant: "primary",
          })
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            text: "Data gagal disimpan !!",
            variant: "danger",
          })
        })
        .finally(() => {
          setModalConfig({ id: "", type: "add", show: false })
          setIsLoading(false)
          setSubmitting(false)
          getData()
        })
    }
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR
    getData() // GET DATA DARI SERVER
    getDropdown()

    return () => {
      setIsLoading(false)
    }
  }, [
    setNavbarTitle,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_program_start,
    dataFilter?.filter?.tgl_program_end,
    dataFilter?.filter?.nama_unit_organisasi,
    dataFilter?.filter?.id_unit_produksi,
    dataFilter?.filter?.id_penanggung_jawab_program,
    dataFilter?.filter?.status_approval_program,
    dataFilter?.filter?.status_approval_program,
    searchKey,
  ])

  // TABLE COMPONENT
  const Table = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : ""
      const convert = data.substring(0, 3)

      if (convert === "APP")
        return {
          variant: "outline-approved",
          label: "APPROVED",
        }
      if (convert === "VER")
        return {
          variant: "outline-verified",
          label: "VERIFIED",
        }
      if (convert === "REV")
        return {
          variant: "outline-revised",
          label: "REVISION",
        }
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        }
      return {
        variant: "outline-pending",
        label: "PENDING",
      }
      // return "PENDING";
    }
    return (
      <>
        <CRUDLayout.Table>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Aksi</Th>
              <Th>Informasi Program</Th>
              <Th>Nama Program</Th>
              <Th>Unit Organisasi</Th>
              <Th>Unit Produksi</Th>
              <Th>Penanggung Jawab</Th>
              <Th>Lama Periode</Th>
              <Th>Status Approval</Th>
            </Tr>
          </Thead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_sub_kelompok_anggaran}>
                  <Td className="text-center" width="20px">
                    {TableNumber(
                      dataFilter?.pagination?.page,
                      dataFilter?.pagination?.dataLength,
                      index
                    )}
                  </Td>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ButtonGroup>
                        <ReadButton
                          size="sm"
                          onClick={() => {
                            setModalConfig({
                              show: true,
                              type: "detail",
                              id: val?.id_program,
                            })
                          }}
                        />
                        {checkStatus(val.status_approval_program).label === "REVISION" && (
                          <UpdateButton
                            size="sm"
                            onClick={() => {
                              setModalConfig({
                                show: true,
                                type: "edit",
                                id: val?.id_program,
                              })
                            }}
                          />
                        )}
                      </ButtonGroup>
                    </div>
                  </TdFixed>
                  <TdFixed>
                    <div>
                      {val.tgl_program
                        ? DateConvert(new Date(val?.tgl_program)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_program ?? ""}</div>
                  </TdFixed>
                  <Td>{val.nama_program ?? "-"}</Td>
                  <Td>{val.nama_unit_organisasi ?? "-"}</Td>
                  <Td>{val.nama_unit_produksi ?? "-"}</Td>
                  <Td>{val.nama_penanggung_jawab_program ?? "-"}</Td>
                  <Td>{val.lama_periode_program + " Hari" ?? "-"}</Td>
                  <TdFixed>
                    <ApprovalStatusButton
                      variant={checkStatus(val.status_approval_program).variant}
                    >
                      {checkStatus(val.status_approval_program).label}
                    </ApprovalStatusButton>
                  </TdFixed>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>
        {data.length > 0 && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
              dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
                dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch
                onChange={(e) => {
                  onInputSearch(e)
                }}
              />
              <FilterButton
                active={dataFilter?.filter?.active}
                onClick={() => setShowModalFilter(true)}
              />
            </div>
          </CRUDLayout.HeadSearchSection>

          {/* Button Section */}
          <CRUDLayout.HeadButtonSection>
            <CreateButton
              onClick={() =>
                setModalConfig({
                  type: "add",
                  show: true,
                  id: "",
                })
              }
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        {/* Alert */}
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />

        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <>
            <b>List Data Program</b>
            <Table />
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        {modalConfig?.show && (
          <ModalAction
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
            dropdown={dropdown}
            formSubmitHandler={formSubmitHandler}
          />
        )}

        {showModalFilter && (
          <ModalFilter
            show={showModalFilter}
            setShow={setShowModalFilter}
            data={dataFilter}
            setData={setDataFilter}
            dropdown={dropdown}
          />
        )}
      </CRUDLayout>
    </>
  )
}
